"use client"

import { TooltipProvider } from "@deltagreen/react-library"
import Decimal from "decimal.js"
import { LazyMotion } from "framer-motion"
import { Provider as JotaiProvider } from "jotai"
import { PropsWithChildren } from "react"
import superjson from "superjson"

superjson.registerCustom<Decimal, string>(
  {
    isApplicable: (v): v is Decimal => Decimal.isDecimal(v),
    serialize: (v) => v.toJSON(),
    deserialize: (v) => new Decimal(v),
  },
  "decimal.js",
)
export function GlobalProvider(props: PropsWithChildren) {
  return (
    <JotaiProvider>
      <LazyMotion features={async () => (await import("@/components/framerMotionFeatures")).default} strict>
        <TooltipProvider>{props.children}</TooltipProvider>
      </LazyMotion>
    </JotaiProvider>
  )
}
